<template>
  <div class="home">
    <div class="banner"><img src="../assets/images/banner1.jpg" alt="" /></div>
    <div class="current-position">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span class="icon-home"></span>首页</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/exam' }">考试</el-breadcrumb-item>
        <!-- <el-breadcrumb-item>{{ licence_name }}</el-breadcrumb-item> -->
      </el-breadcrumb>
    </div>
    <div class="results-box">
      <div class="score-box">
        <dl>
          <dt class="pic-item">
            <el-image
              v-if="this.avatar != ''"
              style="width: 100px; height: 100px"
              :src="avatar"
              fit="cover"
            />
            <el-image
              v-if="this.avatar == ''"
              style="width: 100px; height: 100px"
              src="https://img0.drivedu.com.cn/default.jpg"
              fit="cover"
            />
          </dt>
          <dd>考生：{{ real_name }}</dd>
          <dd>分数：{{ now_score }}</dd>
          <dd>用时：{{ now_time }}</dd>
          <div class="pic-result">
            <img
              v-if="now_pass == '未通过'"
              src="../assets/images/notThrough.png"
              alt=""
            />
            <img
              v-if="now_pass != '未通过'"
              src="../assets/images/through.png"
              alt=""
            />
          </div>
        </dl>
      </div>
      <div class="results-list">
        <template>
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="subject_name" label="科目" width="180">
            </el-table-column>
            <el-table-column prop="score" label="成绩" width="180">
            </el-table-column>
            <el-table-column prop="time" label="用时" width="180">
            </el-table-column>
            <el-table-column prop="is_pass" label="结果" width="180">
            </el-table-column>
            <el-table-column prop="start_time" label="考试时间">
            </el-table-column>
          </el-table>
          <div style="margin-top:16px;width:100%;height:50px;text-align:right;">
            <el-button type="primary" plain @click="again()">
              再做一次
            </el-button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { gettranscripts } from '../api/tiku'
import { getResult } from '@/utils/auth'
export default {
  data() {
    return {
      tableData: [],
      real_name: '',
      user_id: 0,
      avatar: '',
      now_score: 0,
      now_time: 0,
      now_pass: 0
    }
  },
  components: {},
  created() {
    if (getResult() === undefined) {
      this.user_id = ''
    } else {
      this.real_name = JSON.parse(getResult()).real_name
      this.user_id = JSON.parse(getResult()).userid
      this.avatar = JSON.parse(getResult()).avatar
    }
    this.getList()
  },
  methods: {
    getList() {
      let params = {
        user_id: this.user_id
      }
      gettranscripts(params).then(res => {
        if (res.code == 0) {
          this.now_score = res.data[0].score
          this.now_pass = res.data[0].is_pass
          this.tableData = res.data
          for (let i = 0; i < this.tableData.length; i++) {
            let result = parseInt(this.tableData[i].time)
            let h =
              Math.floor(result / 3600) < 10
                ? '0' + Math.floor(result / 3600)
                : Math.floor(result / 3600)
            let m =
              Math.floor((result / 60) % 60) < 10
                ? '0' + Math.floor((result / 60) % 60)
                : Math.floor((result / 60) % 60)
            let s =
              Math.floor(result % 60) < 10
                ? '0' + Math.floor(result % 60)
                : Math.floor(result % 60)

            let res = ''
            if (h !== '00') {
              res += `${h}:`
            }
            if (m !== '00') {
              res += `${m}:`
            } else {
              res += `00:`
            }
            if (s !== '00') {
              res += `${s}`
            } else {
              res += `00`
            }
            this.tableData[i].time = res
          }
          this.now_time = res.data[0].time
        }
      })
    },
    again() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less">
//成绩单页面
.results-box {
  padding: 30px 0;
  .score-box {
    width: 50%;
    margin: 30px auto;
    dl {
      position: relative;
      .pic-item {
        display: inline-block;
        border-radius: 50%;
        vertical-align: top;
        img {
          border-radius: 50%;
        }
      }
      dd {
        padding: 0 20px;
        display: inline-block;
        line-height: 100px;
      }
      .pic-result {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  .results-list {
    width: 80%;
    margin: 0 auto;
  }
}
</style>
